import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import illustration from "../../assets/icon/connect-wallet.svg";
import Btn from "../btn";
import { useWeb3Modal } from "@web3modal/wagmi/react";

const ConnectWallet = () => {
  const { open } = useWeb3Modal();

  return (
    <Flex
      border={" 1px solid #2C2C2C"}
      background={" #000"}
      padding={"40px 32px"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={"40px"}
      borderRadius={"16px"}
      width={"100%"}
      maxWidth={"364px"}
    >
      <Image src={illustration} width={"133px"} height={"120px"} />

      <Flex direction={"column"} alignItems={"center"}>
        <Text
          color={"#FFF"}
          fontSize={"20px"}
          fontStyle={"normal"}
          fontWeight={600}
          lineHeight={"30px"}
        >
          No Wallet Connected
        </Text>

        <Text
          color={"#F4F4F4"}
          fontSize={"14px"}
          fontStyle={"normal"}
          fontWeight={400}
          fontFamily={"Avenir Next"}
          lineHeight={"22px"}
        >
          Connect a wallet to stake and get rewards.
        </Text>
      </Flex>

      <Btn
        width={"100%"}
        height={"56px"}
        textColor={"#000"}
        backgroundColor={"#FFF"}
        text={"Connect Wallet"}
        onClick={() => {
          open();
        }}
        br={"8px"}
        padding={"24px 12px"}
        fontSize={"16px"}
        fontWeight={500}
        lineHeight={"24px"}
        icon
      />
    </Flex>
  );
};

export default ConnectWallet;
