import { Button, Flex, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import foxIcon from "../../assets/soical-icons/fox.svg";
import chevronDown from "../../assets/icon/chevron-down.svg";
import walletIcon from "../../assets/icon/wallet.svg";
import WalletBlack from "../../assets/icon/wallet-ico.svg";
import { useAccount, useDisconnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import logo from "../../assets/logo/wolf.svg";

const Option = () => {
  const { isConnected, address } = useAccount();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return !isConnected ? (
    <Button
      bg="#FFF"
      padding="12px 24px"
      display="flex"
      alignItems="center"
      gap="10px"
      _hover={{
        bg: "#FFF",
      }}
      onClick={() => {
        open();
      }}
    >
      <Image src={WalletBlack} alt="wallet" />
      <Text
        color={"#000"}
        //  fontFamily={"Metropolis"}
        fontSize={"14px"}
        fontStyle={"normal"}
        fontWeight={500}
        lineHeight={"22px"}
      >
        Connect Wallet
      </Text>
    </Button>
  ) : (
    <Flex maxW={"230px"} width={"100%"} direction={"column"} gap={"6px"}>
      <Flex
        maxW={"230px"}
        width={"100%"}
        padding={"8px 16px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderRadius={"12px"}
        border={"1px solid #2C2C2C"}
        background={"#000"}
      >
        <Flex gap={"16px"} alignItems={"center"}>
          <Image
            src={logo}
            width={{ base: "25.607px", md: "25.607px" }}
            height={{ base: "24px", md: "24px" }}
          />

          <Flex direction={"column"}>
            <Text
              color={"#FFF"}
              //  fontFamily={"Metropolis"}
              fontSize={"12px"}
              fontStyle={"normal"}
              fontWeight={500}
              lineHeight={"18px"}
            >
              Connected
            </Text>

            <Text
              color={"#F4F4F4"}
              //  fontFamily={"Metropolis"}
              fontSize={"10px"}
              fontStyle={"normal"}
              fontWeight={400}
              lineHeight={"normal"}
            >
              {address?.slice(0, 6) +
                "..." +
                address?.slice(address.length - 4, address.length)}
            </Text>
          </Flex>
        </Flex>

        <Flex
          width={"18px"}
          height={"18px"}
          p={"5px"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={"4px"}
          background={"rgba(242, 242, 242, 0.20)"}
          cursor={"pointer"}
          onClick={handleToggle}
        >
          <Image src={chevronDown} height={"100%"} width={"100%"} alt="down" />
        </Flex>
      </Flex>

      {isOpen && (
        <Flex
          maxW={"230px"}
          width={"100%"}
          padding={"16px"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={"8px"}
          border={"1px solid #4C4C4C"}
          background={"#2C2C2C"}
          boxShadow={"0px 2px 24px 0px rgba(231, 231, 231, 0.05)"}
        >
          <Flex
            cursor={"pointer"}
            width={"100%"}
            padding={"8px 16px"}
            alignItems={"center"}
            gap={"12px"}
            borderRadius={"6px"}
            background={"rgba(0, 0, 0, 0.20)"}
            onClick={() => {
              disconnect();
              setIsOpen(false);
            }}
          >
            <Image src={walletIcon} height={"14px"} width={"14px"} alt="icon" />

            <Text
              color={"#FFF"}
              //  fontFamily={"Metropolis"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={600}
              lineHeight={"22px"}
            >
              Disconnect Wallet
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default Option;
