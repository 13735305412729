import { extendTheme } from "@chakra-ui/react";
import "@fontsource/metropolis"; // Defaults to weight 400

const theme = extendTheme({
  fonts: {
    body: `'Metropolis', sans-serif`,
    heading: `'Metropolis', sans-serif`,
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});

export default theme;
