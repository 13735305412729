import { Box, Flex, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Stat from "./stat";
import { readContract } from "@wagmi/core";
import { config } from "../../utils/config";
import {
  stakingContractABI,
  stakingContractAddress,
} from "../../utils/contracts";
import { useAccount } from "wagmi";
import { formatUnits } from "viem";
import { useSelector } from "react-redux";
import { sleep } from "../available-balance/dropdwon";

const Stats = () => {
  const { isConnected, address } = useAccount();

  const { recall, claimRecall } = useSelector((state: any) => state?.general);

  const [isStatsLoading, setIsStatsLoading] = useState(false);
  const [totalStaked, setTotalStaked] = useState("");
  const [totalRewards, setTotalRewards] = useState("");
  const [myStake, setMyStake] = useState<any>(null);

  const fetchStats = async () => {
    setIsStatsLoading(true);
    try {
      // @ts-ignore
      const totalStaked: any = await readContract(config, {
        abi: stakingContractABI,
        functionName: "totalStaked",
        address: stakingContractAddress,
        args: [],
      });

      // @ts-ignore
      const totalRewards: any = await readContract(config, {
        abi: stakingContractABI,
        functionName: "totalRewards",
        address: stakingContractAddress,
        args: [],
      });

      setTotalStaked(formatUnits(totalStaked?.toString() || "", 9));
      setTotalRewards(formatUnits(totalRewards?.toString() || "", 9));
      return {
        totalStaked: formatUnits(totalStaked?.toString() || "", 9),
        totalRewards: formatUnits(totalRewards?.toString() || "", 9),
      };
    } catch (error) {
    } finally {
      setIsStatsLoading(false);
    }
  };

  const fetchMyStakes = async () => {
    if (!address) {
      setMyStake(null);
      return;
    }
    try {
      // @ts-ignore
      const myStake: any = await readContract(config, {
        abi: stakingContractABI,
        functionName: "getInvestor",
        address: stakingContractAddress,
        args: [address],
      });
      //
      const tempStake = {
        totalStaked: formatUnits(myStake?.totalStaked?.toString() || "", 9),
        totalRewards: formatUnits(myStake?.totalRewards?.toString() || "", 9),
        totalInvestments: formatUnits(
          myStake?.totalInvestments?.toString() || "",
          9
        ),
      };
      setMyStake(tempStake);
      return tempStake;
    } catch (error) {}
  };

  const recallMyStakes = async () => {
    let tempStake = await fetchMyStakes();

    while (tempStake?.totalStaked == myStake?.totalStaked) {
      await sleep(5);
      tempStake = await fetchMyStakes();
    }
  };

  const recallStats = async () => {
    let stats = await fetchStats();

    while (
      JSON.stringify(stats) == JSON.stringify({ totalStaked, totalRewards })
    ) {
      await sleep(5);
      stats = await fetchStats();
    }
  };

  useEffect(() => {
    fetchMyStakes();
    fetchStats();
  }, [isConnected, address]);

  useEffect(() => {
    if (recall) {
      recallMyStakes();
      recallStats();
    }
  }, [recall]);

  useEffect(() => {
    if (claimRecall) {
      recallStats();
    }
  }, [claimRecall]);

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <Flex
      mt={{ base: "-72px", md: "-120px" }}
      width={"100%"}
      height={{ base: undefined, md: "280px" }}
      justifyContent={"center"}
      position={"relative"}
    >
      <Box
        opacity={0.7}
        filter="blur(77px)"
        position={"absolute"}
        width={{ base: "100%", md: "689px" }}
        height={{ base: "190px", md: "226px" }}
        borderRadius={{ base: "100%", md: "689px" }}
        top={0}
        background={"#919191"}
        zIndex={0}
      />
      <Flex
        zIndex={1}
        background={" rgba(0, 0, 0, 0.50)"}
        paddingY={{ base: "40px", md: undefined }}
        direction={{ base: "column", md: "row" }}
        width={"100%"}
        height={{ base: undefined, md: "160px" }}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"56px"}
      >
        {isStatsLoading && !totalStaked ? (
          <Spinner size="md" color="white" />
        ) : (
          <>
            <Stat
              stat={{
                titleBase: "Total Staked Amount",
                titleMd: "My Stake",
                amount: myStake?.totalStaked || 0,
              }}
            />
            <Flex
              width="1px"
              height="46px"
              opacity="0.3"
              background="#B8B8B9"
              display={{ base: "none", md: "block" }}
            />
            <Stat
              stat={{
                titleBase: "Total Staked",
                titleMd: "Total Staked",
                amount: totalStaked || 0,
              }}
            />
            <Flex
              width="1px"
              height="46px"
              opacity="0.3"
              background="#B8B8B9"
              display={{ base: "none", md: "block" }}
            />
            <Stat
              stat={{
                titleBase: "Rewards Claimed",
                titleMd: "Total Reward Claimed",
                amount: totalRewards || 0,
              }}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default Stats;
