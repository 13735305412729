import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

const Stat = ({ stat }: any) => {
  const title = useBreakpointValue({ base: stat?.titleBase, md: stat.titleMd });

  return (
    <Flex direction="column" gap="20px" alignItems="center">
      <Text
        color="#F4F4F4"
        fontSize="14px"
        fontStyle="normal"
        fontWeight={400}
        lineHeight="22px"
      >
        {title}
      </Text>
      <Text
        color="#FFF"
        fontSize="24px"
        fontStyle="normal"
        fontWeight={600}
        lineHeight="28px"
      >
        {stat.amount || 0} FELIX
      </Text>
    </Flex>
  );
};

export default Stat;
