import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Box } from "@chakra-ui/react";
import Index from "./pages";

function App() {
  return (
    <>
      <Box bgSize={"cover"} minH={"100vh"}>
        <Router>
          <Routes>
            <Route path="/" element={<Index />} />
          </Routes>
        </Router>
      </Box>
    </>
  );
}

export default App;
