import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import openIco from "../../assets/icon/accordion2.svg";
import closeIco from "../../assets/icon/accordion1.svg";

const CustomItem = ({ index, title, desc }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <AccordionItem
      key={index}
      width={"100%"}
      padding={"24px"}
      background={"#2C2C2C"}
      border={"0px solid transparent"}
    >
      <h2>
        <AccordionButton onClick={handleToggle}>
          <Flex
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text
              textAlign="left"
              textColor="#FFF"
              fontSize={"16px"}
              fontWeight={600}
              lineHeight={"24px"}
            >
              {title}
            </Text>
            <Image
              src={isOpen ? closeIco : openIco}
              width={"24px"}
              height={"24px"}
            />
          </Flex>
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Text
          textAlign="left"
          textColor="#B8B8B9"
          fontSize={"16px"}
          fontWeight={400}
          lineHeight={"24px"}
        >
          {desc}
        </Text>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default CustomItem;
