import { Flex, Image, Text, Box } from "@chakra-ui/react";
import React from "react";
import mainLogo from "../../assets/logo/name-logo.svg";
import fbIcon from "../../assets/soical-icons/Facebook.svg";
import instIcon from "../../assets/soical-icons/Instagram.svg";
// import linkedinIcon from "../../assets/soical-icons/linkedin.svg";
import Tiktok from "../../assets/soical-icons/Tiktok.svg";
import {
  IconBrandTiktok,
  IconBook,
  IconBrandMedium,
} from "@tabler/icons-react";

const Footer = () => {
  return (
    <Flex
      paddingX={{ base: "16px", md: undefined }}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      direction={"column"}
      gap={"24px"}
    >
      <Image
        src={mainLogo}
        width={{ base: "196px", md: "196px" }}
        height={{ base: "56px", md: "56px" }}
      />
      <Text
        textAlign={"center"}
        color={"#FFF"}
        fontSize={{ base: "16px", md: "16px" }}
        fontStyle={"normal"}
        fontWeight={{ base: 400, md: 400 }}
        lineHeight={{ base: "24px", md: "24px" }}
        maxW={"787px"}
      >
        Felix is a dog and meme coin cryptocurrency with utility. The Latin word
        felix means happy, fortunate. It is destined to make people happy. It's
        invented by the legendary 'cap' aka 'Avax Soldier' who raised a dog
        crypto named AKITA to over a 3B dollar MC. Make sure to visit{" "}
        <a
          href="https://felixinu.io/"
          target="_blank"
          style={{ color: "#496491" }}
        >
          Felixinu.io{" "}
        </a>{" "}
        for all other info, such as telegram and contact adress.
      </Text>

      <Flex alignItems={"center"} gap={"16px"}>
        <Image
          src={fbIcon}
          width={"40px"}
          height={"40px"}
          cursor="pointer"
          alt="fackbook-icon"
          onClick={() => {
            window.open("https://www.facebook.com/FelixInu");
          }}
        />
        <Image
          src={instIcon}
          width={"40px"}
          height={"40px"}
          cursor="pointer"
          alt="instagram-icon"
          onClick={() => {
            window.open("https://www.instagram.com/felixinueth/");
          }}
        />

        <Box
          position="relative"
          width="40px"
          height="40px"
          cursor="pointer"
          onClick={() => {
            window.open("https://www.tiktok.com/@felixinueth");
          }}
        >
          <Image
            src={Tiktok}
            width="100%"
            height="100%"
            alt="tiktok-icon"
            draggable={false}
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <IconBrandTiktok color="white" size={30} stroke={1} />
          </Box>
        </Box>

        <Box
          position="relative"
          width="40px"
          height="40px"
          cursor="pointer"
          onClick={() => {
            window.open("https://felixinu.gitbook.io/felixinu");
          }}
        >
          <Image
            src={Tiktok}
            width="100%"
            height="100%"
            alt="gitbook-icon"
            cursor="pointer"
            draggable={false}
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <IconBook color="white" size={30} stroke={1} />
          </Box>
        </Box>
        <Box
          position="relative"
          width="40px"
          height="40px"
          cursor="pointer"
          onClick={() => {
            window.open("https://felixinu.medium.com/");
          }}
        >
          <Image
            src={Tiktok}
            width="100%"
            height="100%"
            alt="medium-icon"
            cursor="pointer"
            draggable={false}
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <IconBrandMedium color="white" size={30} stroke={1} />
          </Box>
        </Box>
      </Flex>
      <Text
        textAlign={"center"}
        color={"#FFF"}
        fontSize={{ base: "14px", md: "14px" }}
        fontStyle={"normal"}
        fontWeight={{ base: 400, md: 400 }}
        lineHeight={{ base: "22px", md: "22px" }}
      >
        © 2024 | All rights reserved by FelixInu.
      </Text>
    </Flex>
  );
};

export default Footer;
