import { Box, Flex, Image, Input, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Btn from "../btn";
import circleLogo from "../../assets/logo/logo.svg";
import wolfIco from "../../assets/logo/wolf.svg";
import Dropdown, { sleep } from "./dropdwon";
import { useAccount } from "wagmi";
import {
  stakingContractABI,
  stakingContractAddress,
  tokenContractABI,
  tokenContractAddress,
} from "../../utils/contracts";
import { readContract } from "@wagmi/core";
import { config } from "../../utils/config";
import { formatUnits } from "viem";
import { useDispatch, useSelector } from "react-redux";
import { updateIsPaused } from "../../store/slice/generalSlice";

const BalanceCard = () => {
  const { isConnected, address } = useAccount();

  const dispatch = useDispatch();
  const { recall, claimRecall } = useSelector((state: any) => state?.general);

  const inputRef = useRef<any>(null);

  const [inputValue, setInputValue] = useState("");
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const [stakeInfoLoading, setStakeInfoLoading] = useState(false);
  const [balance, setBalance] = useState("");
  const [stakeInfo, setStakeInfo] = useState<any>(null);

  const handleMaxClick = () => {
    if (isConnected) {
      setInputValue(balance);
    }
  };

  const checkIsPaused = async () => {
    try {
      // @ts-ignore
      const isPaused = await readContract(config, {
        abi: stakingContractABI,
        functionName: "paused",
        address: stakingContractAddress,
      });
      dispatch(updateIsPaused(isPaused));
      return isPaused;
    } catch (err: any) {
      console.log(err?.message);
    }
  };

  const fetchBalance = async () => {
    setIsBalanceLoading(true);
    try {
      // @ts-ignore
      const balance: any = await readContract(config, {
        abi: tokenContractABI,
        functionName: "balanceOf",
        address: tokenContractAddress,
        args: [address],
      });
      //
      setBalance(formatUnits(balance?.toString() || "", 9));
      return formatUnits(balance?.toString() || "", 9);
    } catch (error) {
      console.error(error);
    } finally {
      setIsBalanceLoading(false);
    }
  };

  const recallFetchBalance = async () => {
    let balanceInfo = await fetchBalance();

    while (Number(balanceInfo || 0) == Number(balance)) {
      await sleep(5);
      balanceInfo = await fetchBalance();
    }
  };

  const fetchStakeInfo = async () => {
    setStakeInfoLoading(true);
    try {
      // @ts-ignore
      const stakeInfo = await readContract(config, {
        abi: stakingContractABI,
        functionName: "getInvestor",
        address: stakingContractAddress,
        args: [address],
      });
      setStakeInfo(stakeInfo);
    } catch (error) {
      console.error(error);
    } finally {
      setStakeInfoLoading(false);
    }
  };

  useEffect(() => {
    checkIsPaused();
    if (isConnected) {
      fetchBalance();
      fetchStakeInfo();
    } else {
      setBalance("");
      setStakeInfo(null);
    }
  }, [isConnected]);

  useEffect(() => {
    if (recall || claimRecall) {
      checkIsPaused();
      recallFetchBalance();
      // fetchStakeInfo();
    }
  }, [recall, claimRecall]);

  return (
    <Flex
      paddingX={{ base: "16px", md: "0px" }}
      width={"100%"}
      justifyContent={"center"}
    >
      {/* first's div main card design */}
      <Flex
        position={"relative"}
        mt={"130px"}
        maxWidth={"720px"}
        width={"100%"}
        background={"rgba(0, 0, 0, 0.80)"}
        borderRadius={"16px"}
        //  overflow={"hidden"}
      >
        <Box
          opacity={0.7}
          filter="blur(97px)"
          position={"absolute"}
          width={{ base: "285px", md: "285px" }}
          height={{ base: "285px", md: "285px" }}
          borderRadius={{ base: "285px", md: "285px" }}
          top={"-27px"}
          left={"-117px"}
          background={"rgba(255, 248, 242, 0.40)"}
          zIndex={1}
        />
        <Flex
          zIndex={2}
          borderRadius={"16px"}
          backdropFilter={"blur(4.5px)"}
          border={"1px solid #1C1C1C"}
          // border={"1px solid red"}
          width={"100%"}
          padding={{ base: "16px", md: "40px" }}
        >
          <Flex
            zIndex={99}
            alignItems={"flex-start"}
            width={"100%"}
            flexDirection={"column"}
            gap={"24px"}
            alignSelf={"center"}
          >
            <Flex justifyContent={"space-between"} width={"100%"}>
              <Flex
                height={{ base: "48px", md: "64px" }}
                direction={"column"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Text
                  color={"#B8B8B9"}
                  //  fontFamily={"Metropolis"}
                  fontSize={"16px"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  lineHeight={"24px"}
                >
                  Available Balance
                </Text>
                <Text
                  color={"#FFF"}
                  fontSize={{
                    base: "24px",
                    md: "48px",
                  }}
                  fontStyle={"normal"}
                  fontWeight={500}
                  lineHeight={{ base: "24px", md: "64px" }}
                >
                  {!isConnected ? (
                    "0"
                  ) : isBalanceLoading && !balance ? (
                    <Spinner size="md" color="#fff" />
                  ) : (
                    Number(balance)?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    }) || 0
                  )}{" "}
                  FELIX
                </Text>
              </Flex>

              <Image
                src={circleLogo}
                width={{ base: "48px", md: "64px" }}
                height={{ base: "48px", md: "64px" }}
              />
            </Flex>

            <Flex width={"100%"} height={"1px"} backgroundColor={"#2C2C2C"} />

            <Flex justifyContent={"space-between"} width={"100%"}>
              <Flex alignItems={"center"} gap={"12px"}>
                <Flex
                  width={"32px"}
                  height={"32px"}
                  borderRadius={"32px"}
                  bg={"#000000"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Image src={wolfIco} height={"18.442px"} width={"22.857px"} />
                </Flex>

                <Text
                  color={"#FAFAFA"}
                  fontSize={{ base: "18px", md: "24px" }}
                  fontStyle={"normal"}
                  fontWeight={{ base: 600, md: 600 }}
                  lineHeight={{ base: "28px", md: "28px" }}
                >
                  Felix Inu
                </Text>
              </Flex>

              {/* <Text
                color={"#FAFAFA"}
                fontSize={{ base: "18px", md: "24px" }}
                fontStyle={"normal"}
                fontWeight={{ base: 600, md: 600 }}
                lineHeight={{ base: "28px", md: "28px" }}
              >
                {!isConnected ? (
                  "0"
                ) : stakeInfoLoading ? (
                  <Spinner size="sm" color="#fff" />
                ) : (
                  Number(
                    formatUnits(stakeInfo?.totalStaked?.toString() || "", 9)
                  )?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  }) || 0
                )}{" "}
                FELIX
              </Text> */}
            </Flex>

            <Flex
              width={"100%"}
              height={{ base: "56px", md: "56px" }}
              padding={"8px 12px 8px 16px"}
              justifyContent={"space-between"}
              alignItems={"center"}
              borderRadius={"8px"}
              border={"1px solid #4C4C4C"}
              background={"#2C2C2C"}
              onClick={() => {
                inputRef?.current?.focus();
              }}
            >
              <Flex
                height={"100%"}
                direction={"column"}
                // gap={"10px"}
                justifyContent={"space-between"}
                width={"100%"}
                pb={"6px"}
                alignItems={"flex-start"}
              >
                <Text
                  color={"#FFF"}
                  fontSize={{ base: "10px", md: "10px" }}
                  fontStyle={"normal"}
                  fontWeight={{ base: 400, md: 400 }}
                  lineHeight={{ base: "normal", md: "normal" }}
                >
                  <span style={{ marginRight: "4px" }}>Staking</span>
                  <span>Amount</span>
                </Text>

                <Input
                  mt={"3px"}
                  //  zIndex={1000}
                  // border={"1px solid red"}
                  variant="unstyled"
                  placeholder="00"
                  height={"22px"}
                  textColor={"#B8B8B9"}
                  _placeholder={{ color: "#B8B8B9" }}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  ref={inputRef}
                />
              </Flex>

              <Btn
                width={"64px"}
                height={"32px"}
                textColor={"#FFF"}
                backgroundColor={"#000"}
                text={"MAX"}
                onClick={handleMaxClick}
                br={"5px"}
                padding={"10px"}
                fontSize={"10px"}
                fontWeight={700}
                lineHeight={"18px"}
                letterSpacing={"2px"}
              />
            </Flex>

            <Dropdown amount={inputValue} setAmount={setInputValue} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BalanceCard;
