import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import CustomAccordion from "./custom-accordion";
import bgWolfwithMOon from "../../assets/bg/wolf-howling-against-moon.png";

const FAQ = () => {
  const faqs = [
    {
      title: "What is Felix?",
      desc: (
        <>
          Felix is a dog and meme coin cryptocurrency with utility. The Latin
          word felix means happy, fortunate. It is destined to make people
          happy.
          <br /> Felix is invented by the legendary 'cap' aka 'Avax Soldier' who
          raised a meme coin named AKITA to over a 3B dollar MC.
        </>
      ),
    },
    {
      title: "How does Felix Coin work?",
      desc: (
        <>
          You can buy Felix on uniswap. Either you hold it or you can stake it
          on this platform, to get extra Felix rewards.
          <br /> Make sure to visit{" "}
          <a
            href="https://felixinu.io/"
            target="_blank"
            style={{ color: "#496491" }}
          >
            Felixinu.io{" "}
          </a>{" "}
          for all other info.
        </>
      ),
    },
    {
      title: "How do I stake my Felix?",
      desc: (
        <>
          1) Connect your Ethereum wallet with the staking platform, which is
          holding your Felix. Choose your preferred web3 wallet, for example
          Metamask, to connect.
          <br />
          2) Fill in the amount of Felix you want to stake which is available on
          your ETH wallet.
          <br />
          3) Choose the amount of days you want to stake your Felix.
          <br />
          4) Click on the 'Approve' button to approve the Felix.
          <br />
          5) After approval, click on the 'Stake' button to stake your Felix.
          <br />
          6) Confirm the transaction in Metamask or chosen web3 wallet.
          <br />
        </>
      ),
    },
    {
      title: "What rewards do I earn from staking Felix coin?",
      desc: (
        <>
          The rewards you get depend on the duration of your stake. It is put in
          fixed reward percentage. The reward percentage is the interest rate
          earned on an investment in on your selected package.
          <br />
          You are able to withdraw your rewards after the lock time ends. Go to
          'Felix - Staking' to claim your rewards by hitting 'Claim Reward'.
          <br />
          60 days: 1.67%
          <br />
          90 days: 3%
          <br />
          120 days: 5%
          <br />
          365 days: 20%
          <br />
          E.g:
          <br />
          If you stake 100.000 Felix for 1 year, you will earn 20% or 20.000
          Felix
        </>
      ),
    },
    {
      title: "Can I unstake my Felix at any time?",
      desc: (
        <>
          Staking means you are commiting for the chosen duration. The rewards
          you have build over time can be withdrawn after lock time ends.
        </>
      ),
    },
  ];
  return (
    <Flex
      bgImage={bgWolfwithMOon}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      width={"100%"}
      // height={"767px"}
      justifyContent={"center"}
      paddingX={{ base: "16px", md: undefined }}
    >
      <Flex
        direction={"column"}
        gap={{ base: "24px", md: "64px" }}
        maxWidth={"810px"}
        width={"100%"}
      >
        <Text
          color={"#FFF"}
          fontSize={{ base: "48px", md: "48px" }}
          fontStyle={"normal"}
          fontWeight={{ base: 500, md: 500 }}
          lineHeight={{ base: "64px", md: "64px" }}
          letterSpacing={"2px"}
          textAlign={"center"}
          background={"linear-gradient(93deg, #FFF 1.92%, #888282 89.33%)"}
          backgroundClip={"text"}
        >
          Frequently Asked Questions
        </Text>
        <CustomAccordion list={faqs} />
      </Flex>
    </Flex>
  );
};

export default FAQ;
