import { Flex, Image, Spinner, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import chevronDown from "../../assets/icon/chevron-down.svg";
import Btn from "../btn";
import { readContract, writeContract } from "@wagmi/core";
import { config } from "../../utils/config";
import {
  stakingContractABI,
  stakingContractAddress,
  tokenContractABI,
  tokenContractAddress,
} from "../../utils/contracts";
import { DAY, LAUNCH_TIME } from "../../constants";
import { useAccount, useReadContract } from "wagmi";
import { formatUnits, parseUnits } from "viem";
import { useDispatch, useSelector } from "react-redux";
import { recallFunctions } from "../../store/slice/generalSlice";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import moment from "moment";

export const sleep = async (seconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

const Dropdown = ({ amount, setAmount }: any) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const { isConnected, address } = useAccount();
  const { open } = useWeb3Modal();

  const { isPaused } = useSelector((state: any) => state?.general);

  const [isOpen, setIsOpen] = useState(false);
  const [isPlansLoading, setIsPlansLoading] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isStakeLoading, setIsStakeLoading] = useState(false);
  const [planIds, setPlanIds] = useState<any>([]);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [allowanceInfo, setAllowanceInfo] = useState<any>("");

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const checkLaunchTime = () => {
    const launchTime = LAUNCH_TIME;
    const now = moment();
    const difference = moment(launchTime).diff(now, "seconds");
    console.log(difference);
    return difference;
  };

  const handleApprove = async () => {
    setIsApproveLoading(true);
    try {
      // @ts-ignore
      await writeContract(config, {
        abi: tokenContractABI,
        functionName: "approve",
        address: tokenContractAddress,
        args: [stakingContractAddress, parseUnits(amount, 9)],
      });
      let allowance = await fetchAllowance();

      while (Number(allowance || 0) < Number(amount)) {
        await sleep(5);
        allowance = await fetchAllowance();
      }
      setIsApproveLoading(false);
    } catch (e: any) {
      if (
        e?.message?.includes("User denied transaction signature") ||
        e?.message?.includes(
          "MetaMask Tx Signature: User denied transaction signature."
        )
      ) {
        toast({
          title: "Transaction rejected",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsApproveLoading(false);
        setIsStakeLoading(false);
      } else {
        toast({
          title: e?.message || e?.toString() || "Something went wrong!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsApproveLoading(false);
        setIsStakeLoading(false);
      }
    }
  };

  const handleSubmit = async () => {
    if (checkLaunchTime() > 0) {
      toast({
        title: `Staking will be live at ${moment(LAUNCH_TIME)
          .utc()
          .format("DD MMM YYYY HH:mm A")} UTC`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!isConnected) {
      toast({
        title: "Please connect your wallet",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!selectedPlan) {
      toast({
        title: "Please select a plan",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (Number(amount) === 0) {
      toast({
        title: "Please enter a valid amount",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (isPaused) {
      toast({
        title: "Staking is paused",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsStakeLoading(true);
    try {
      //
      // if (Number(allowanceInfo) < Number(amount)) {
      //   await handleApprove();
      // }
      let allowance = await fetchAllowance();
      if (Number(allowance) < Number(amount)) {
        await handleApprove();
      }
      // @ts-ignore
      const stakeInfo = await writeContract(config, {
        abi: stakingContractABI,
        functionName: "invest",
        address: stakingContractAddress,
        args: [selectedPlan?.id, parseUnits(amount, 9)],
      });
      dispatch(recallFunctions(true));
      setTimeout(() => {
        dispatch(recallFunctions(false));
      }, 1500);
      toast({
        title: "Staked successfully",
        description: "Balances will be updated after transaction confirmation.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setAmount("");
    } catch (e: any) {
      if (
        e?.message?.includes("User denied transaction signature") ||
        e?.message?.includes(
          "MetaMask Tx Signature: User denied transaction signature."
        )
      ) {
        toast({
          title: "Transaction rejected",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else if (e?.message?.includes("EnforcedPause")) {
        toast({
          title: "Staking is paused",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else if (e?.message?.includes("Insufficient allowance")) {
        toast({
          title: "Please approve tokens first. Insufficient allowance",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: e?.message || e?.toString() || "Something went wrong!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setIsStakeLoading(false);
    }
  };

  const fetchPlans = async () => {
    setIsPlansLoading(true);
    const tempPlans: any = [];
    try {
      // @ts-ignore
      const plans = await readContract(config, {
        abi: stakingContractABI,
        functionName: "investmentPlanId",
        address: stakingContractAddress,
        args: [],
      });

      for (let i = 1; i <= Number(plans?.toString()); i++) {
        // @ts-ignore
        const plansInfo: any = await readContract(config, {
          abi: stakingContractABI,
          functionName: "investmentPlans",
          address: stakingContractAddress,
          args: [i],
        });

        const tempPlanIds = plansInfo?.map((plan: any) => plan?.toString());
        if (tempPlanIds?.length === 4) {
          tempPlans.push({
            id: Number(tempPlanIds[0]),
            duration: Number(tempPlanIds[1]) / DAY,
            reward: Number(tempPlanIds[2]) / 100,
            isActive: Number(tempPlanIds[3]),
          });
        }
      }
      const filteredPlans = tempPlans?.filter(
        (plan: any) => plan.isActive === 1
      );
      setPlanIds(filteredPlans);
      if (!selectedPlan) {
        setSelectedPlan(filteredPlans[0]);
      }
      setIsPlansLoading(false);
    } catch (e: any) {
      setIsPlansLoading(false);
    }
  };

  const fetchAllowance = async () => {
    try {
      // @ts-ignore
      const allowanceInfo: any = await readContract(config, {
        abi: tokenContractABI,
        functionName: "allowance",
        address: tokenContractAddress,
        args: [address, stakingContractAddress],
      });

      setAllowanceInfo(formatUnits(allowanceInfo?.toString() || "", 9));

      return Number(formatUnits(allowanceInfo?.toString() || "", 9));
    } catch (e: any) {}
  };

  useEffect(() => {
    if (isConnected) {
      fetchAllowance();
    }
  }, [isConnected, address]);

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <Flex
      mt={"-8px"}
      width={"100%"}
      // height={{ base: "56px", md: "56px" }}
      gap={"16px"}
      alignItems={"center"}
      direction={{ base: "column", md: "row" }}
    >
      <Flex
        maxWidth={{ base: undefined, md: "410px" }}
        width={"100%"}
        position={"relative"}
      >
        <Flex
          height={{ base: "56px", md: "56px" }}
          maxWidth={{ base: undefined, md: "410px" }}
          width={"100%"}
          borderRadius={"8px"}
          border={"1px solid #4C4C4C"}
          background={"#2C2C2C"}
          //  height={"100%"}
          padding={"10px 12px 14px 16px"}
          justifyContent={"space-between"}
          alignItems="center"
        >
          {isPlansLoading ? (
            <Spinner size="sm" color="white" />
          ) : (
            <>
              <Flex
                height={"100%"}
                direction={"column"}
                // gap={"10px"}
                justifyContent={"space-between"}
                width={"141px"}
                pb={"6px"}
                alignItems={"flex-start"}
              >
                <Text
                  color={"#FFF"}
                  fontSize={{ base: "10px", md: "10px" }}
                  fontStyle={"normal"}
                  fontWeight={{ base: 400, md: 400 }}
                  lineHeight={{ base: "normal", md: "normal" }}
                >
                  Choose Staking Term
                </Text>

                <Text
                  color={"#FFF"}
                  fontSize={{ base: "18px", md: "18px" }}
                  fontStyle={"normal"}
                  fontWeight={{ base: 600, md: 600 }}
                  lineHeight={{ base: "28px", md: "28px" }}
                >
                  {!selectedPlan
                    ? "Select Plan"
                    : selectedPlan?.duration + " Days"}
                </Text>
              </Flex>

              <Flex alignItems={"center"} gap={"16px"}>
                <Text
                  color={"#FFF"}
                  fontSize={{ base: "12px", md: "12px" }}
                  fontStyle={"normal"}
                  fontWeight={{ base: 700, md: 700 }}
                  lineHeight={{ base: "18px", md: "18px" }}
                  letterSpacing={"2px"}
                >
                  {selectedPlan?.reward || "0"}% Reward
                </Text>

                <Flex
                  width={"18px"}
                  height={"18px"}
                  p={"5px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  borderRadius={"4px"}
                  background={"rgba(242, 242, 242, 0.20)"}
                  cursor={"pointer"}
                  onClick={handleToggle}
                >
                  <Image
                    src={chevronDown}
                    height={"100%"}
                    width={"100%"}
                    alt="down"
                    // cursor={"pointer"}
                  />
                </Flex>
              </Flex>
            </>
          )}
        </Flex>

        {isOpen && (
          <Flex
            zIndex={10}
            position={"absolute"}
            top={"62px"}
            // height={{ base: "56px", md: "56px" }}
            maxWidth={{ base: undefined, md: "410px" }}
            width={"100%"}
            borderRadius={"12px"}
            border={"1px solid #4C4C4C"}
            background={"#2C2C2C"}
            //  height={"100%"}
            padding={{ base: "12px", md: "24px" }}
            alignItems={"flex-start"}
            direction={"column"}
            gap={"16px"}
            boxShadow={"0px 2px 24px 0px rgba(231, 231, 231, 0.05)"}
          >
            {planIds?.map((item: any, index: number) => (
              <Flex
                cursor={"pointer"}
                key={index}
                height={"36px"}
                justifyContent={"space-between"}
                width={"100%"}
                p={{ base: "8px 12px", md: "8px 16px" }}
                alignItems={"center"}
                _hover={{ background: "#232323" }}
                borderRadius={" 6px"}
                // background={"rgba(0, 0, 0, 0.20)"}
                onClick={() => {
                  setIsOpen(false);
                  setSelectedPlan(item);
                }}
              >
                <Text
                  color={"#FFF"}
                  fontSize={{ base: "18px", md: "18px" }}
                  fontStyle={"normal"}
                  fontWeight={{ base: 600, md: 600 }}
                  lineHeight={{ base: "28px", md: "28px" }}
                >
                  {item.duration || 0} Days
                </Text>

                <Text
                  color={"#FFF"}
                  fontSize={{ base: "14px", md: "14px" }}
                  fontStyle={"normal"}
                  fontWeight={{ base: 400, md: 400 }}
                  lineHeight={{ base: "22px", md: "22px" }}
                >
                  {item.reward || 0}% Reward
                </Text>
              </Flex>
            ))}
          </Flex>
        )}
      </Flex>
      <Btn
        zIndex={0}
        width={{ base: "100%", md: "214px" }}
        height={"56px"}
        text={isConnected ? "Stake" : "Connect Wallet"}
        onClick={() => {
          if (!isConnected) {
            open();
          } else {
            handleSubmit();
          }
        }}
        isDisabled={isStakeLoading || isApproveLoading}
        isLoading={isStakeLoading || isApproveLoading}
      />
    </Flex>
  );
};

export default Dropdown;
