import { Flex } from "@chakra-ui/react";
import React from "react";
import Navbar from "../navbar/navbar";
import bgWolf from "../../assets/bg/wild-wolf.png";
import BalanceCard from "./balance-card";

const AvailableBalance = () => {
  return (
    <Flex
      bgImage={bgWolf}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      width={"100%"}
      height={{ base: "812px", md: "767px" }}
      //justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}

      // paddingTop={"0px"}
    >
      <Navbar />
      <BalanceCard />
    </Flex>
  );
};

export default AvailableBalance;
