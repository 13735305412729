import { Accordion, Flex } from "@chakra-ui/react";
import React from "react";
import CustomItem from "./accordion-item";

const CustomAccordion = ({ list }: any) => {
  return (
    <Accordion
      width="100%"
      height="100%"
      allowMultiple
      allowToggle
      //  border={"1px solid red"}
      //mt={"-64px"}
    >
      <Flex
        gap={"24px"}
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height="100%"
      >
        {list?.map((faq: any, index: number) => (
          //   <AccordionItem
          //     key={index}
          //     width={"100%"}
          //     padding={"24px"}
          //     background={"#2C2C2C"}
          //     border={"0px solid transparent"}
          //   >
          //     <h2>
          //       <AccordionButton onClick={handleToggle}>
          //         <Flex
          //           width={"100%"}
          //           justifyContent={"space-between"}
          //           alignItems={"center"}
          //         >
          //           <Text
          //             textAlign="left"
          //             textColor="#FFF"
          //             fontSize={"16px"}
          //             fontWeight={600}
          //             lineHeight={"24px"}
          //           >
          //             {faq?.title}
          //           </Text>
          //           <Image
          //             src={isOpen ? openIco : closeIco}
          //             width={"24px"}
          //             height={"24px"}
          //           />
          //         </Flex>
          //       </AccordionButton>
          //     </h2>
          //     <AccordionPanel pb={4}>
          //       <Text
          //         textAlign="left"
          //         textColor="#B8B8B9"
          //         fontSize={"16px"}
          //         fontWeight={400}
          //         lineHeight={"24px"}
          //       >
          //         {faq?.desc}
          //       </Text>
          //     </AccordionPanel>
          //   </AccordionItem>
          <CustomItem index={index} title={faq?.title} desc={faq?.desc} />
        ))}
      </Flex>
    </Accordion>
  );
};

export default CustomAccordion;
