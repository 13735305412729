import { Flex, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import StakedCard from "./staked-card";
import ConnectWallet from "./connect-wallet";
import { useAccount } from "wagmi";
import {
  stakingContractABI,
  stakingContractAddress,
} from "../../utils/contracts";
import { readContract } from "@wagmi/core";
import { config } from "../../utils/config";
import { formatUnits } from "viem";
import { DAY } from "../../constants";
import { useSelector } from "react-redux";
import { sleep } from "../available-balance/dropdwon";

const Staked = () => {
  const { isConnected, address } = useAccount();

  const { recall, claimRecall } = useSelector((state: any) => state?.general);

  const [stakedInfo, setStakedInfo] = useState<any>([]);
  const [isStakedInfoLoading, setIsStakedInfoLoading] = useState(false);

  const compareArrays = (arr1: any, arr2: any) => {
    // Check if both arrays have the same length
    if (arr1?.length !== arr2?.length) {
      return false;
    }
    // Check if `isWithdrawn` and `isClaimed` are the same for corresponding objects
    for (let i = 0; i < arr1?.length; i++) {
      if (
        arr1[i]?.isWithdrawn !== arr2[i]?.isWithdrawn ||
        arr1[i]?.isClaimed !== arr2[i]?.isClaimed
      ) {
        return false;
      }
    }
    // If all checks pass, arrays are considered equal based on the criteria
    return true;
  };

  const fetchStakedInfo = async () => {
    setIsStakedInfoLoading(true);
    const tempArr: any = [];
    try {
      // @ts-ignore
      const stakeInfo: any = await readContract(config, {
        abi: stakingContractABI,
        functionName: "getUserInvestmentIds",
        address: stakingContractAddress,
        args: [address],
      });

      for (let index = 0; index < stakeInfo?.length; index++) {
        // @ts-ignore
        const stake: any = await readContract(config, {
          abi: stakingContractABI,
          functionName: "getInvestment",
          address: stakingContractAddress,
          args: [Number(stakeInfo?.[index]?.toString())],
        });

        // @ts-ignore
        const rewardInfo: any = await readContract(config, {
          abi: stakingContractABI,
          functionName: "getWithdrawableReward",
          address: stakingContractAddress,
          args: [Number(stake?.id?.toString())],
        });

        // @ts-ignore
        const currentReward: any = await readContract(config, {
          abi: stakingContractABI,
          functionName: "getCurrentReward",
          address: stakingContractAddress,
          args: [Number(stake?.id?.toString())],
        });

        // @ts-ignore
        const stakePlanInfo: any = await readContract(config, {
          abi: stakingContractABI,
          functionName: "investmentPlans",
          address: stakingContractAddress,
          args: [Number(stake?.planId?.toString())],
        });

        tempArr.push({
          id: Number(stake?.id?.toString()),
          start: Number(stake?.start?.toString()),
          amount: Number(formatUnits(stake?.amount?.toString() || "", 9)),
          isClaimed: Number(stake?.isClaimed?.toString()),
          isWithdrawn: Number(stake?.isWithdrawn?.toString()),
          withdrawableReward: Number(
            formatUnits(rewardInfo?.toString() || "", 9)
          ),
          currentReward: Number(
            formatUnits(currentReward?.toString() || "", 9)
          ),
          plan: {
            id: Number(stakePlanInfo?.[0]?.toString()),
            duration: Number(stakePlanInfo?.[1]?.toString()) / DAY,
            reward: Number(stakePlanInfo?.[2]?.toString()) / 100,
            isActive: Number(stakePlanInfo?.[3]?.toString()),
          },
        });
      }

      const finalArr = tempArr.sort((a: any, b: any) => b.start - a.start);
      setStakedInfo(finalArr);
      return finalArr;
      //
    } catch (error) {
      console.error(error);
    } finally {
      setIsStakedInfoLoading(false);
    }
  };

  const recallStakedInfo = async () => {
    let stakeInfo = await fetchStakedInfo();

    while (compareArrays(stakeInfo, stakedInfo)) {
      await sleep(5);
      stakeInfo = await fetchStakedInfo();
    }
  };

  useEffect(() => {
    if (isConnected) {
      fetchStakedInfo();
    } else {
      setStakedInfo([]);
    }
  }, [isConnected, address]);

  useEffect(() => {
    if (recall || claimRecall) {
      recallStakedInfo();
    }
  }, [recall, claimRecall]);

  return (
    <Flex
      paddingX={{ base: "16px", md: undefined }}
      background={"#000000"}
      width={"100%"}
      //height={{ base: undefined, md: "767px" }}
      gap={"40px"}
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"relative"}
    >
      <Text
        color={"#FFF"}
        fontSize={{ base: "48px", md: "48px" }}
        fontStyle={"normal"}
        fontWeight={{ base: 500, md: 500 }}
        lineHeight={{ base: "64px", md: "64px" }}
        letterSpacing={"2px"}
        textAlign={"center"}
        background={"linear-gradient(93deg, #FFF 1.92%, #888282 89.33%)"}
        backgroundClip={"text"}
      >
        Staked Amount
      </Text>

      <Text
        color={"#B8B8B9"}
        fontSize={{ base: "16px", md: "16px" }}
        fontStyle={"normal"}
        fontWeight={{ base: 400, md: 400 }}
        lineHeight={{ base: "24px", md: "24px" }}
        letterSpacing={"2px"}
        textAlign={"center"}
      >
        This page shows the staked amount, calculated rewards, chosen duration
        of stake and the amount of rewards which can be claimed.
      </Text>

      {isConnected ? (
        <Flex width={"100%"} position={"relative"}>
          <Flex
            width={"100%"}
            zIndex={1}
            direction={"column"}
            gap={"40px"}
            alignItems={"center"}
          >
            {isStakedInfoLoading && stakedInfo?.length === 0 ? (
              <Spinner size="md" color="white" />
            ) : stakedInfo.length === 0 ? (
              <Text
                color={"#FFF"}
                fontSize={{ base: "16px", md: "20px" }}
                fontStyle={"normal"}
                fontWeight={{ base: 500, md: 500 }}
                lineHeight={{ base: "24px", md: "24px" }}
              >
                You don't have any stakes yet!
              </Text>
            ) : (
              <Flex
                gap={"40px"}
                alignItems={"center"}
                width={"100%"}
                justifyContent={"center"}
                direction={{ base: "column", md: "row" }}
                flexWrap="wrap"
              >
                {stakedInfo.map((stake: any) => (
                  <StakedCard key={stake.id} stakeInfo={stake} />
                ))}
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : (
        <Flex
          zIndex={10}
          backdropFilter={"blur(8px)"}
          width={"100%"}
          //padding={"120px 0px"}
          paddingTop={"120px"}
          paddingBottom={{ base: "350px", md: "120px" }}
          justifyContent={"center"}
          alignItems={"center"}
          background={"rgba(0, 0, 0, 0.8)"}
        >
          <ConnectWallet />
        </Flex>
      )}
    </Flex>
  );
};

export default Staked;
