import { createSlice } from "@reduxjs/toolkit";

export interface GeneralState {
  recall: boolean;
  isPaused: boolean;
  claimRecall: boolean;
}

const initialState: GeneralState = {
  recall: false,
  claimRecall: false,
  isPaused: false,
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    recallFunctions: (state, action) => {
      state.recall = action?.payload;
    },
    claimRecallFunctions: (state, action) => {
      state.claimRecall = action?.payload;
    },
    updateIsPaused: (state, action) => {
      state.isPaused = action?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { recallFunctions, updateIsPaused, claimRecallFunctions } =
  generalSlice.actions;

export default generalSlice.reducer;
