import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { createStorage, noopStorage } from "wagmi";

import { mainnet } from "wagmi/chains";

// 1. Get projectId from https://cloud.walletconnect.com
const projectId = "bf2156a62596b5b93d81efacdd77094a";

// 2. Create wagmiConfig
const metadata = {
  name: "FelixInu",
  description: "Web3Modal Example",
  url: "https://web3modal.com", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet] as const;
const chainIds = chains.map((chain) => chain.id).join(",");

const key = `wagmi-chains-${chainIds}`;

export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  storage: createStorage({
    key,
    storage:
      typeof window !== "undefined" && window.localStorage
        ? window.localStorage
        : noopStorage,
  }),
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
});
