import { Flex } from "@chakra-ui/react";
import React from "react";
import Stats from "../components/stats/stats";
import Staked from "../components/staked/staked";
import FAQ from "../components/faq/faq";
import Footer from "../components/footer/footer";
import AvailableBalance from "../components/available-balance/available-balance";

const Index = () => {
  return (
    <Flex
      background={"#000000"}
      width={"100%"}
      mx={"auto"}
      paddingBottom={{ base: "64px", md: "120px" }}
    >
      <Flex
        direction={"column"}
        width={"100%"}
        height={"100%"}
        //  maxWidth={"1540px"}
        //  mx={"auto"}
        gap={{ base: "72px", md: "120px" }}
      >
        <AvailableBalance />
        <Stats />
        <Staked />
        <FAQ />
        <Footer />
      </Flex>
    </Flex>
  );
};

export default Index;
