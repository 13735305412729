import { Flex, Image, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import reward from "../../assets/icon/reward.svg";
import circleLogo from "../../assets/logo/logo.svg";
import Btn from "../btn";
import moment from "moment";
import { writeContract } from "@wagmi/core";
import { config } from "../../utils/config";
import {
  stakingContractABI,
  stakingContractAddress,
} from "../../utils/contracts";
import { useDispatch, useSelector } from "react-redux";
import { claimRecallFunctions } from "../../store/slice/generalSlice";

const StakedCard = ({ stakeInfo }: any) => {
  const toast = useToast();

  const dispatch = useDispatch();
  const { isPaused } = useSelector((state: any) => state.general);

  const [isLoading, setIsLoading] = useState<any>(-1);

  const calculateRewardDays = () => {
    const startDate = moment?.unix(stakeInfo?.start);
    const duration = stakeInfo?.plan?.duration;
    const currentDate = moment();

    const endDate = moment(startDate).add(duration, "minutes");
    const remainingDays = endDate.diff(currentDate, "minutes");
    const remainingMinutes = endDate.diff(currentDate, "minutes");

    if (stakeInfo?.isClaimed) {
      return "Reward Claimed";
    }
    if (stakeInfo?.isWithdrawn) {
      return "Capital Withdrawn";
    }
    if (remainingDays < 0 && remainingMinutes < 0) {
      return "Reward has arrived";
    } else if (isPaused) {
      return "Staking is paused";
    } else if (remainingDays < 1) {
      return "Reward is arriving today";
    } else {
      return `Reward in ${remainingDays} days`;
    }
  };

  const handleClaimReward = async () => {
    setIsLoading(stakeInfo?.id);
    try {
      // @ts-ignore
      const withdrawInfo = await writeContract(config, {
        abi: stakingContractABI,
        functionName: "withdrawReward",
        address: stakingContractAddress,
        args: [Number(stakeInfo?.id)],
      });
      dispatch(claimRecallFunctions(true));
      setTimeout(() => {
        dispatch(claimRecallFunctions(false));
      }, 1500);
      //
      toast({
        title: "Reward claimed successfully",
        description: "Balances will be updated after transaction confirmation.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (e: any) {
      toast({
        description: e?.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error(e);
    } finally {
      setIsLoading(-1);
    }
  };

  const withdrawStake = async () => {
    setIsLoading(stakeInfo?.id);
    try {
      // @ts-ignore
      const withdrawInfo = await writeContract(config, {
        abi: stakingContractABI,
        functionName: "withdrawAmount",
        address: stakingContractAddress,
        args: [Number(stakeInfo?.id)],
      });

      dispatch(claimRecallFunctions(true));
      setTimeout(() => {
        dispatch(claimRecallFunctions(false));
      }, 1500);
      toast({
        title: "Capital withdrawn successfully",
        description: "Balances will be updated after transaction confirmation.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (e: any) {
      toast({
        description: e?.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error(e);
    } finally {
      setIsLoading(-1);
    }
  };

  return (
    <Flex
      border={"1px solid #2C2C2C"}
      background={"rgba(44, 44, 44, 0.50)"}
      maxWidth={"500px"}
      width={"100%"}
      padding={"32px"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={"32px"}
      borderRadius={"16px"}
    >
      <Flex
        height={"30px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Text
          color={"#FFF"}
          fontSize={{ base: "14px", md: "14px" }}
          fontStyle={"normal"}
          fontWeight={{ base: 400, md: 400 }}
          lineHeight={{ base: "22px", md: "22px" }}
        >
          ID {stakeInfo?.id}
        </Text>

        <Flex
          padding={"8px 12px"}
          alignItems={"center"}
          gap={"10px"}
          // width={"151px"}
          background={"#2C2C2C"}
        >
          <Image src={reward} width={"14px"} height={"14px"} />
          <Text
            color={"#F4F4F4"}
            fontSize={{ base: "12px", md: "12px" }}
            fontStyle={"normal"}
            fontWeight={{ base: 400, md: 400 }}
            lineHeight={{ base: "18px", md: "18px" }}
          >
            {calculateRewardDays()}
          </Text>
        </Flex>
      </Flex>

      <Flex
        height={"40px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Flex width={"157px"} alignItems={"center"} gap={"8px"}>
          <Image
            src={circleLogo}
            width={{ base: "40px", md: "40px" }}
            height={{ base: "40px", md: "40px" }}
          />
          <Flex flexDirection={"column"} alignItems={"flex-start"}>
            <Text
              color={"#B8B8B9"}
              fontSize={{ base: "14px", md: "14px" }}
              fontStyle={"normal"}
              fontWeight={{ base: 400, md: 400 }}
              lineHeight={{ base: "22px", md: "22px" }}
            >
              Staked Amount
            </Text>

            <Text
              color={"#FFF"}
              fontSize={{ base: "20px", md: "20px" }}
              fontStyle={"normal"}
              fontWeight={{ base: 600, md: 600 }}
              lineHeight={{ base: "30px", md: "30px" }}
            >
              {Number(stakeInfo?.amount)?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }) || 0}{" "}
              FELIX
            </Text>
          </Flex>
        </Flex>

        <Flex direction={"column"} alignItems={"flex-end"}>
          <Text
            color={"#F4F4F4"}
            fontSize={{ base: "12px", md: "12px" }}
            fontStyle={"normal"}
            fontWeight={{ base: 400, md: 400 }}
            lineHeight={{ base: "18px", md: "18px" }}
          >
            Reward Calculated
          </Text>
          <Text
            color={"#F4F4F4"}
            fontSize={{ base: "16px", md: "16px" }}
            fontStyle={"normal"}
            fontWeight={{ base: 400, md: 400 }}
            lineHeight={{ base: "24px", md: "24px" }}
          >
            {Number(stakeInfo?.currentReward)?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) || 0}{" "}
            FELIX
          </Text>
        </Flex>
      </Flex>

      <Flex
        background={"#000"}
        padding={" 8px 16px"}
        height={"36px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Text
          color={"#FFF"}
          fontSize={{ base: "14px", md: "14px" }}
          fontStyle={"normal"}
          fontWeight={{ base: 400, md: 400 }}
          lineHeight={{ base: "22px", md: "22px" }}
        >
          {stakeInfo?.plan?.duration || 0} Days Plan
        </Text>

        <Text
          color={"#FFF"}
          fontSize={{ base: "12px", md: "12px" }}
          fontStyle={"normal"}
          fontWeight={{ base: 700, md: 700 }}
          lineHeight={{ base: "18px", md: "18px" }}
          letterSpacing={"2px"}
          textTransform={"uppercase"}
        >
          {stakeInfo?.plan?.reward || 0}% Reward
        </Text>
      </Flex>

      {calculateRewardDays() === "Reward has arrived" ||
      calculateRewardDays() === "Reward Claimed" ||
      calculateRewardDays() === "Capital Withdrawn" ||
      isPaused ? (
        <Flex
          height={"40px"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Flex flexDirection={"column"} alignItems={"flex-start"}>
            <Text
              color={"#B8B8B9"}
              fontSize={{ base: "14px", md: "14px" }}
              fontStyle={"normal"}
              fontWeight={{ base: 400, md: 400 }}
              lineHeight={{ base: "22px", md: "22px" }}
            >
              Claimable Reward
            </Text>

            <Text
              color={"#FFF"}
              fontSize={{ base: "20px", md: "20px" }}
              fontStyle={"normal"}
              fontWeight={{ base: 600, md: 600 }}
              lineHeight={{ base: "30px", md: "30px" }}
            >
              {Number(stakeInfo?.withdrawableReward)?.toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 2,
                }
              )}{" "}
              FELIX
            </Text>
          </Flex>
          <Btn
            width={{ base: undefined, md: "154px" }}
            height={"46px"}
            text={
              stakeInfo?.isClaimed
                ? "Reward Claimed"
                : isPaused && !stakeInfo?.isWithdrawn
                ? "Withdraw Capital"
                : stakeInfo?.isWithdrawn
                ? "Capital Withdrawn"
                : "Claim Reward"
            }
            onClick={() => {
              if (stakeInfo?.isClaimed) return;
              if (stakeInfo?.isWithdrawn) return;
              if (isPaused) {
                withdrawStake();
                return;
              }
              handleClaimReward();
            }}
            backgroundColor={
              stakeInfo?.isClaimed || stakeInfo?.isWithdrawn ? "#000" : "#fff"
            }
            textColor={
              stakeInfo?.isClaimed || stakeInfo?.isWithdrawn ? "#FFF" : "#000"
            }
            isDisabled={stakeInfo?.id === isLoading}
            isLoading={stakeInfo?.id === isLoading}
          />
        </Flex>
      ) : (
        <Flex
          height={"40px"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        ></Flex>
      )}
    </Flex>
  );
};

export default StakedCard;
