import React from "react";
import { Button, Flex, Image } from "@chakra-ui/react";
import walletIcon from "../assets/icon/walletIcon-dark.svg";
const Btn = ({
  textColor,
  text,
  backgroundColor,
  onClick,
  padding,
  width,
  height,
  br,
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
  icon,
  isDisabled,
  isLoading,
}: any) => {
  return (
    <Button
      borderRadius={br ? br : "8px"}
      justifyContent={"center"}
      alignItems={"center"}
      width={width}
      height={height}
      bg={backgroundColor ? backgroundColor : "#FFF"}
      color={textColor ? textColor : "#000"}
      _hover={{ bg: `${backgroundColor}D9` }}
      onClick={onClick}
      padding={padding ? padding : "12px 24px"}
      fontSize={fontSize ? fontSize : "16px"}
      fontWeight={fontWeight ? fontWeight : 500}
      lineHeight={lineHeight ? lineHeight : "24px"}
      letterSpacing={letterSpacing}
      isDisabled={isDisabled}
      isLoading={isLoading}
    >
      <Flex alignItems={"center"} gap={"10px"}>
        {icon && <Image src={walletIcon} width={""} height={""} />}
        {text}
      </Flex>
    </Button>
  );
};

export default Btn;
