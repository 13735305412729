import { Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import logoName from "../../assets/logo/name-logo.svg";
import logo from "../../assets/logo/wolf.svg";
import Option from "./option";
const Navbar = () => {
  const logoSrc = useBreakpointValue({
    base: logo,
    md: logoName,
  });

  return (
    <Flex
      maxWidth={"1540px"}
      mx={"auto"}
      height={"120px"}
      backdropBlur={"8px"}
      padding={{ base: "10px", md: "10px 24px" }}
      width={"100%"}
      alignItems={"flex-start"}
      justifyContent={"space-between"}
    >
      <Image
        src={logoSrc}
        width={{ base: "47px", md: "133px" }}
        height={{ base: "58px", md: "38px" }}
      />

      <Option />
    </Flex>
  );
};

export default Navbar;
